import { List, ListItem } from "@mui/material";
import { Grid, Select, SelectItem, TextField, Checkbox, Button } from "@platform-ui/design-system";
import React, { Dispatch } from "react";
import { useStoreContext, Action } from "../../../../../Store";
import { UPDATE_FIELD_TYPE, UPDATE_FIELD_NAME, TOGGLE_FIELD_REQUIRED, TOGGLE_FIELD_READONLY, ADD_FIELD_OPTION, UPDATE_FIELD_OPTION, DELETE_FIELD, UPDATE_BUTTON_ONCLICK } from "../../../actionTypes";
import { IntegrationAppFormState } from "../../../state";

interface FieldBlockProps {
  fieldName: string;
  fieldType: string;
  onClick: string;
  required: boolean;
  readonly: boolean;
  tab: string;
  tabIndex: number;
  fieldGroupIndex: number;
  fieldIndex: number;
}

export const FieldBlock = ({
  fieldName,
  fieldType,
  onClick,
  required,
  readonly,
  tab,
  tabIndex,
  fieldGroupIndex,
  fieldIndex
}: FieldBlockProps) => {
  const { state, dispatch } = useStoreContext() as { state: IntegrationAppFormState, dispatch: Dispatch<Action> };

  const updateField = (event) => {
    dispatch({
      type: UPDATE_FIELD_TYPE,
      payload: {
        tab: tab,
        tabIndex: tabIndex,
        fieldGroupIndex: fieldGroupIndex,
        fieldIndex: fieldIndex,
        type: event.target.value,
        required: event.target.value == 'checkbox' ? true : required
      }
    })
  }

  const updateFieldName = (event) => {
    dispatch({
      type: UPDATE_FIELD_NAME,
      payload: {
        tab: tab,
        tabIndex: tabIndex,
        fieldGroupIndex: fieldGroupIndex,
        fieldIndex: fieldIndex,
        name: event.target.value
      }
    })
  }

  const toggleRequired = (event) => {
    dispatch({
      type: TOGGLE_FIELD_REQUIRED,
      payload: {
        tab: tab,
        tabIndex: tabIndex,
        fieldGroupIndex: fieldGroupIndex,
        fieldIndex: fieldIndex,
        type: event.target.value,
        required: !required
      }
    })
  }
  
  const toggleReadonly = (event) => {
    dispatch({
      type: TOGGLE_FIELD_READONLY,
      payload: {
        tab: tab,
        tabIndex: tabIndex,
        fieldGroupIndex: fieldGroupIndex,
        fieldIndex: fieldIndex,
        type: event.target.value,
        readonly: !readonly
      }
    })
  }

  const deleteField = (event) => {
    dispatch({
      type: DELETE_FIELD,
      payload: {
        tab: tab,
        tabIndex: tabIndex,
        fieldGroupIndex: fieldGroupIndex,
        fieldIndex: fieldIndex
      }
    });
  }

  const updateOnClickHandler = (value) => {
    dispatch({
      type: UPDATE_BUTTON_ONCLICK,
      payload: { tab, tabIndex, fieldGroupIndex, fieldIndex, onClick: value }
    });
  }

  // TODO(Xander): Reenable all field option code when the structure is reworked - see IntegrationApps/state.ts
  // const addFieldOption = (event) => {
  //   dispatch({
  //     type: ADD_FIELD_OPTION,
  //     payload: {
  //       tab: tab,
  //       tabIndex: tabIndex,
  //       fieldGroupIndex: fieldGroupIndex,
  //       fieldIndex: fieldIndex,

  //     }
  //   })
  // }

  // const updateFieldOption =  (event) => {
  //   dispatch({
  //     type: UPDATE_FIELD_OPTION,
  //     payload: {
  //       tab: tab,
  //       tabIndex: tabIndex,
  //       fieldGroupIndex: fieldGroupIndex,
  //       fieldIndex: fieldIndex,
  //     }
  //   })
  // }

  return (
    <Grid container direction='row'>
      <Grid item>
        <Select
          a11yLabel="fieldType"
          dsOnChange={updateField}
          label="Field Type"
          placeholder="Select Type"
          required
          value={fieldType}>
          <SelectItem value='text'>Text</SelectItem>
          <SelectItem value='textarea'>Text Area</SelectItem>
          <SelectItem value='password'>Password</SelectItem>
          {/* <SelectItem value='select'>Select</SelectItem> */}
          <SelectItem value='checkbox'>Checkbox</SelectItem>
          <SelectItem value='button'>Button</SelectItem>
          <SelectItem value='switch'>Switch</SelectItem>
          <SelectItem value='datepicker'>DatePicker</SelectItem>
        </Select>
      </Grid>
      <Grid item>
        <TextField dsOnChange={updateFieldName} label='Name' value={fieldName} required={true} />
      </Grid>
      <Grid item sx={{marginTop:'32px !important'}}> 
        <Checkbox dsOnChange={toggleRequired} label='Required' checked={fieldType === 'checkbox' ? false : required} />
      </Grid>
      <Grid item sx={{marginTop:'32px !important'}}> 
        <Checkbox dsOnChange={toggleReadonly} label='Read-Only' checked={readonly} />
      </Grid>
      {/* {fieldType === 'select' && <Grid container item>
        <List>
          {state.ui_layout['tabs'][tabIndex][tab]['fieldGroups'][fieldGroupIndex]['fields'].map((option) => {
            <ListItem></ListItem>
          })}
        </List>
        <Button dsOnClick={addFieldOption}>Add New Option</Button>
      </Grid>} */}
      {
        fieldType === 'button' &&
        <Grid item>
          <TextField
            a11yLabel='onClickFunction'
            label='onClick'
            value={onClick || ''}
            dsOnChange={(event) => {
              updateOnClickHandler(event.target.value);
            }}
            multiline={true}
            rows={4}
            maxRows={5}
            wrap={'hard'}
          />  
        </Grid>
      }
      <Grid item sx={{marginTop:'24px !important'}}>
        <Button icon='remove_circle_outline' dsOnClick={deleteField}>Delete Field</Button>
      </Grid>
    </Grid>
  )
}

export default FieldBlock;
